import { memo, useCallback, useContext } from 'react';
import {
  Annotation,
  useProjectUpdateClippingMethodMutation,
  ClipMethods,
  ProjectFragment,
} from '../../../../types/graphqlTypes';
import { useParams } from 'react-router-dom';
import { apolloClient } from '../../../../apollo';
import { PROJECT_FRAGMENT } from '../../../../graphql/project';
import { ViewerContext } from '../../../../contexts/ViewerContext';
import OrthoviewIcon from '../../../../assets/icons/orthoview.svg?react';
import { useToggleHide } from '../../../../hooks/modules/project/useToggleHide';

const AnnotationsOrthoviewIcon_: React.FC2<{
  annotation?: Pick<Annotation, 'identifier' | 'groupIdentifier' | 'name'> & {
    type?: Annotation['type'];
    visible?: boolean;
  };
}> = ({ annotation }) => {
  const [updateClippingMethod] = useProjectUpdateClippingMethodMutation();
  const { projectId = '' } = useParams();
  const { in2DMode, planeId, toggle2DMode } = useContext(ViewerContext);
  const { toggleHide } = useToggleHide();
  const project = apolloClient.readFragment<ProjectFragment>({
    fragment: PROJECT_FRAGMENT,
    id: `Project:${projectId}`,
    fragmentName: 'Project',
  });

  const isolateNewPlane = useCallback(() => {
    if (!annotation || !project) return;
    updateClippingMethod({
      variables: { projectId, annotationIdentifiers: [annotation.identifier], clipMethod: ClipMethods.Isolate },
      optimisticResponse: {
        __typename: 'Mutation',
        projectUpdateClippingMethod: {
          id: projectId,
          state: {
            annotations: project.state.annotations.map((someAnnotation) => {
              if (someAnnotation.identifier === annotation.identifier && someAnnotation.__typename === 'Plane') {
                return {
                  ...someAnnotation,
                  annotationFilter: { ...someAnnotation.annotationFilter, clipMethod: ClipMethods.Isolate },
                };
              }
              return someAnnotation;
            }),
          },
        },
      },
    });
  }, [project, updateClippingMethod, annotation, projectId]);

  const stopIsolateOldPlane = useCallback(() => {
    if (!project) return;
    if (planeId === 'map') return;
    updateClippingMethod({
      variables: { projectId, annotationIdentifiers: [planeId], clipMethod: ClipMethods.None },
      optimisticResponse: {
        __typename: 'Mutation',
        projectUpdateClippingMethod: {
          id: projectId,
          state: {
            annotations: project.state.annotations.map((someAnnotation) => {
              if (someAnnotation.identifier === planeId && someAnnotation.__typename === 'Plane') {
                return {
                  ...someAnnotation,
                  annotationFilter: { ...someAnnotation.annotationFilter, clipMethod: ClipMethods.None },
                };
              }
              return someAnnotation;
            }),
          },
        },
      },
    });
  }, [project, updateClippingMethod, planeId, projectId]);

  const onToggleOrthoview = useCallback(() => {
    if (!project) return;
    if (in2DMode) {
      toggleHide({ identifiers: ['map'], visible: false });
    }
    if (!annotation) {
      stopIsolateOldPlane();
      toggle2DMode('map');
      return;
    }
    if (annotation.type !== 'PLANE') return;
    if (in2DMode) {
      if (annotation.identifier === planeId) {
        stopIsolateOldPlane();
      } else {
        isolateNewPlane();
        stopIsolateOldPlane();
      }
    } else {
      isolateNewPlane();
    }
    toggle2DMode(annotation.identifier);
  }, [annotation, isolateNewPlane, stopIsolateOldPlane, project, toggle2DMode, in2DMode, planeId, toggleHide]);

  const identifier = annotation ? annotation.identifier : 'map';

  return (
    <div
      onClick={onToggleOrthoview}
      className="flex items-center justify-center w-6 h-6 text-[#6F7173] rounded-sm cursor-pointer hover:text-black dark:hover:text-neon-green-300"
    >
      {identifier === planeId ? (
        <OrthoviewIcon className="w-4 h-4 text-black dark:text-neon-green-300" />
      ) : (
        <OrthoviewIcon className="w-4 h-4" />
      )}
    </div>
  );
};
export const AnnotationsOrthoviewIcon = memo(AnnotationsOrthoviewIcon_);
