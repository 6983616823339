import { useLazyQuery } from '@apollo/client';
import { PointCloudCommandManager } from '@pointorama/pointcloud-commander';
import { groupBy, keyBy, orderBy } from 'lodash/fp';
import React, { memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EarthLoader } from '../../components/loaders/EarthLoader';
import { AnnotationContext, AnnotationContextType } from '../../contexts/AnnotationContext';
import { CommandManagerContext } from '../../contexts/CommandManagerContext';
import { PotreeSourcesContext } from '../../contexts/PotreeSourcesContext';
import { RendererContext, RendererContextType } from '../../contexts/RendererContext';
import { RendererReadOnlyContext } from '../../contexts/RendererReadOnlyContext';
import { UserContext } from '../../contexts/UserContext';
import { REQUEST_READ_SAS_TOKEN } from '../../graphql/project';
import { useDefaultPointCloudState } from '../../hooks/modules/project/useDefaultPointCloudState';
import {
  changePointCloudAppearance,
  changePointCloudGradient,
  changePointCloudResolution,
} from '../../hooks/potree/usePointCloudProperties';
import { usePotreeEvents } from '../../hooks/potree/usePotreeEvents';
import { useProject } from '../../hooks/potree/useProject';
import { useRealtimeUpdates } from '../../hooks/potree/useRealtimeUpdates';
import { useRendererShortcuts } from '../../hooks/potree/useRendererShortcuts';
import { EMPTY_ARRAY, ProjectionSystems, ProjectionSystemsByEpsgCode } from '../../utils/constants';
import { RendererHeader } from '../../modules/renderer/RendererHeader';
import { RendererLeftPanel } from '../../modules/renderer/RendererLeftPanel';
import { RendererRightPanel } from '../../modules/renderer/RendererRightPanel';
import { MapTool, ScreenTools } from '../../modules/renderer/ScreenTools';
import {
  Annotation,
  MeasurementUnits,
  ProjectByIdQuery,
  ProjectRequestReadSasTokenQuery,
  ProjectRequestReadSasTokenQueryVariables,
  UserRole,
} from '../../types/graphqlTypes';
import { useLocalStorage } from 'usehooks-ts';
import useOpen from '../../hooks/useOpen';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { CadLayer, CadObjectContext, InitialCadLayer, isLoadedCadLayer } from '../../contexts/CadLayersContext';
import { getCadObjectsByIdentifier } from '../../hooks/potree/useRenderer';
import { isNotNullOrUndefined } from './helpers/isNotNullOrUndefined';
import { WMSLayersContext } from '../../contexts/WmsLayersContext';
import { getPotreeFilter } from './helpers/getPotreeFilter';
import * as THREE from 'three';
import { OrthophotoLayersContext } from '../../contexts/OrthophotoLayersContext';
import Worker from '../../workers/OrthophotoReaderWorker?worker';
import { EventInput, EventOutput } from '../../workers/OrthophotoReaderWorker';
import epsg from 'epsg-index/all.json';
import { usePreviewUpload } from '../../hooks/useUpload';
import { useDecompressCadLayers } from './helpers/decompressCadLayers';
import { isValidProjectionSystem } from '../../modules/project/ProjectProjectionSystemModal';
import { VectorsAreAlmostEqual } from '../../utils/AlmostEqual';
import { ViewerContextProvider } from '../../contexts/ViewerContext';

const ProjectContent_: React.FC2<{ project?: ProjectByIdQuery['projectById'] }> = ({ project }) => {
  usePotreeEvents({ project });
  useRealtimeUpdates();
  useRendererShortcuts();

  useEffect(() => {
    const onTouchEnd = (e: TouchEvent) => {
      e.target?.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          clientX: e.changedTouches[0].clientX,
          clientY: e.changedTouches[0].clientY,
        }),
      );
      e.preventDefault();
    };
    // replace default touchEnd behavior with mouse click. (bugfix: see PK-254)
    window.addEventListener('touchend', onTouchEnd);
    return () => {
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, []);

  const showOldSideBar = localStorage.getItem('showOldSideBar') === 'true';

  const rendererHeaderProps = useMemo(
    () =>
      project?.id && {
        project: {
          id: project?.id,
          name: project?.name,
          description: project.description,
          previewUrl: project.previewUrl,
        },
      },
    [project?.id, project?.name, project?.description, project?.previewUrl],
  );

  const allPanelsTogether = false; // email === 'jonathan@softvalla.com';

  const { mdDevice } = useDeviceSize();

  const [leftPanelOpenStorage, setLeftPanelOpenStorage] = useLocalStorage('left-panel-open', !mdDevice);
  const { onClose: closeLeftPanel, onOpen: openLeftPanel, open: leftPanelOpen } = useOpen(leftPanelOpenStorage);

  useEffect(() => {
    setLeftPanelOpenStorage(leftPanelOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftPanelOpen]);

  const [rightPanelOpenStorage, setRightPanelOpenStorage] = useLocalStorage('right-panel-open', !mdDevice);
  const { onClose: closeRightPanel, onOpen: openRightPanel, open: rightPanelOpen } = useOpen(rightPanelOpenStorage);

  useEffect(() => {
    setRightPanelOpenStorage(rightPanelOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelOpen]);

  const onOpenLeftPanel = useCallback(() => {
    if (leftPanelOpen) return;
    openLeftPanel();
    if (mdDevice) closeRightPanel();
  }, [closeRightPanel, leftPanelOpen, mdDevice, openLeftPanel]);

  const onOpenRightPanel = useCallback(() => {
    if (rightPanelOpen) return;
    openRightPanel();
    if (mdDevice) closeLeftPanel();
  }, [closeLeftPanel, mdDevice, openRightPanel, rightPanelOpen]);

  useEffect(() => {
    if (!mdDevice) return;
    if (leftPanelOpen && rightPanelOpen) {
      closeRightPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdDevice]);

  const hideLeftArrow = mdDevice && rightPanelOpenStorage;
  const hideRightArrow = mdDevice && leftPanelOpenStorage;

  return (
    <div className="flex flex-col w-full h-full bg-white dark:bg-[#313131] dark:text-white">
      {<EarthLoader />}
      {rendererHeaderProps && <RendererHeader {...rendererHeaderProps} minimize={mdDevice} />}
      <div className="flex w-full h-full overflow-auto">
        {!allPanelsTogether && (
          <RendererLeftPanel
            project={project}
            closePanel={closeLeftPanel}
            openPanel={onOpenLeftPanel}
            panelOpen={leftPanelOpen}
            hideArrow={hideLeftArrow}
          />
        )}
        <div className="relative w-full h-full">
          <div className="absolute top-0 left-0 w-full h-full potree_container">
            <div id="potree_render_area">
              <div id="olContainer" className="absolute top-0 left-0 w-full h-full bg-neon-green-300" />
            </div>
            {showOldSideBar && <div id="potree_sidebar_container"></div>}
          </div>
          <MapTool className="absolute bottom-4 left-4" project={project} />
          <ScreenTools className="absolute bottom-4 left-1/2 transform -translate-x-1/2" project={project} />
        </div>
        {!allPanelsTogether ? (
          <RendererRightPanel
            project={project}
            closePanel={closeRightPanel}
            openPanel={onOpenRightPanel}
            panelOpen={rightPanelOpen}
            hideArrow={hideRightArrow}
          />
        ) : (
          <div className="flex flex-col flex-shrink-0 h-full w-96">
            <div className="overflow-auto h-1/2">
              <RendererLeftPanel
                project={project}
                closePanel={closeLeftPanel}
                openPanel={onOpenLeftPanel}
                panelOpen={leftPanelOpen}
                hideArrow={hideLeftArrow}
              />
            </div>
            <div className="overflow-auto h-1/2">
              <RendererRightPanel
                project={project}
                closePanel={closeRightPanel}
                openPanel={onOpenRightPanel}
                panelOpen={rightPanelOpen}
                hideArrow={hideRightArrow}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const ProjectContent = memo(ProjectContent_);

const Project_: React.FC2 = () => {
  const { isLoaded: sourcesLoaded } = useContext(PotreeSourcesContext);
  const { projectId = '' } = useParams();
  const [setAndUploadPreview] = usePreviewUpload();
  const [projectRequestReadSASToken] = useLazyQuery<
    ProjectRequestReadSasTokenQuery,
    ProjectRequestReadSasTokenQueryVariables
  >(REQUEST_READ_SAS_TOKEN, { fetchPolicy: 'network-only' });
  const projectQuery = useProject();
  const currentUser = useContext(UserContext);
  const { organisationId = '' } = useParams();
  const [defaultPointCloudState] = useDefaultPointCloudState();
  const project = projectQuery.data?.projectById;
  const initialProjectCadLayers = project?.state.cadLayers || EMPTY_ARRAY;
  const [cadLayers, setCadLayers] = useState<Record<string, CadLayer | InitialCadLayer | null>>({});
  const { decompressCadLayers } = useDecompressCadLayers();
  const projectionSystem = project?.settings?.projectionSystem;
  useEffect(() => {
    if (!projectionSystem) return;
    if (!isValidProjectionSystem(projectionSystem)) {
      console.error(`Invalid projection system: ${projectionSystem}`);
      return;
    }
    const projectionSystemDefinition = ProjectionSystems[projectionSystem].parameters;
    if (initialProjectCadLayers) {
      const initialProjectCadLayersById = keyBy('identifier', initialProjectCadLayers);
      const newCadLayers = initialProjectCadLayers
        .slice()
        .filter((layer) => !cadLayers[layer.identifier] && !!layer.fileUrl);
      const removedCadLayerIdentifiers = Object.keys(cadLayers).filter(
        (identifier) => !initialProjectCadLayersById[identifier] && !!cadLayers[identifier],
      );
      const newCadLayersByIdentifier = keyBy(
        'identifier',
        newCadLayers.map((layer) => ({ ...layer, status: 'INIT' as const })),
      );
      const newStateLayers: Record<string, CadLayer | InitialCadLayer | null> = {
        ...Object.entries(cadLayers).reduce((acc, [identifier, layer]) => {
          const newLayerInfo = initialProjectCadLayersById[identifier];
          if (!newLayerInfo) return { ...acc, [identifier]: layer };
          return {
            ...acc,
            [identifier]: {
              ...layer,
              visibleState: newLayerInfo.visibleState,
              name: newLayerInfo.name,
            },
          };
        }, {}),
        ...newCadLayersByIdentifier,
        ...removedCadLayerIdentifiers.reduce((acc, identifier) => ({ ...acc, [identifier]: null }), {}),
      };
      setCadLayers(newStateLayers);
      decompressCadLayers({
        layers: Object.values(newStateLayers).filter(isNotNullOrUndefined),
        projectionSystemDefinition,
        layersToProcess: newCadLayers,
      }).then((layers) => {
        const layersByIdentifier = keyBy(
          'identifier',
          layers.map((layer) => ({ ...layer, status: 'LOADED' as const })),
        );
        setCadLayers((layers) => ({ ...layers, ...layersByIdentifier }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProjectCadLayers, projectionSystem]);

  const [pointcloudLoaded, setPointcloudLoaded] = useState(false);
  const [initializedPointclouds, setInitializedPointclouds] = useState<Array<string>>([]);
  const hasProject = !!project;
  const [rendererContext, setRendererContext] = React.useState<RendererContextType>({});
  const commander = useMemo(() => new PointCloudCommandManager(), []);
  const commandManagerContext = useMemo(() => ({ commander }), [commander]);
  const { measurementUnit } = useContext(UserContext);
  const calculationsByIdentifier = useMemo(() => {
    return groupBy('annotationIdentifier', project?.calculations || []);
  }, [project?.calculations]);
  const syncAnnotations = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.annotations || !viewer) return;
      const currentMeasurements = viewer.scene.measurements.filter((measure) => measure.finished);
      const currentMeasurementsByIdentifier = keyBy('identifier', currentMeasurements);
      const annotationsByIdentifier = keyBy('identifier', project.state.annotations);

      project.state.annotations.forEach((annotation) => {
        if (annotation.__typename === 'BoxAnnotation') return;
        const color = annotation.annotationColor
          ? new THREE.Color(
              annotation.annotationColor.r / 255,
              annotation.annotationColor.g / 255,
              annotation.annotationColor.b / 255,
            )
          : new THREE.Color(1, 0, 0);
        if (annotation.__typename === 'PointAnnotation') {
          const currentMeasurement = currentMeasurementsByIdentifier[annotation.identifier];
          const position = new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z);
          if (currentMeasurement) {
            currentMeasurement.visible = annotation.visible;
            currentMeasurement.setHiddenLabels(annotation.hiddenLabels);
            if (
              position.x === currentMeasurement.points[0].position.x &&
              position.y === currentMeasurement.points[0].position.y &&
              position.z === currentMeasurement.points[0].position.z &&
              color.equals(currentMeasurement.color)
            ) {
              return;
            }
            viewer.scene.removeMeasurement(currentMeasurement);
          }
          const measure = new Potree.PointAnnotation({
            viewer: viewer,
            point: { position },
            visible: annotation.visible,
            hiddenLabels: annotation.hiddenLabels,
            color: color,
            finished: true,
            currentTool: viewer.measuringTool.currentTool,
            identifier: annotation.identifier,
            selected: currentMeasurement?.selected || false,
          });
          viewer.scene.addMeasurement(measure);
        } else if (annotation.__typename === 'DistanceAnnotation') {
          const currentMeasurement = currentMeasurementsByIdentifier[annotation.identifier];
          if (currentMeasurement) {
            currentMeasurement.visible = annotation.visible;
            currentMeasurement.setHiddenLabels(annotation.hiddenLabels);
            const pointsAreSame =
              annotation.points.length === currentMeasurement.points.length &&
              annotation.points.every((point, index) => {
                const currentPoint = currentMeasurement.points[index];
                return new THREE.Vector3(point.position.x, point.position.y, point.position.z).equals(
                  currentPoint.position,
                );
              });
            if (pointsAreSame && color.equals(currentMeasurement.color)) return;
            viewer.scene.removeMeasurement(currentMeasurement);
          }
          const measure = new Potree.DistanceAnnotation({
            viewer: viewer,
            points: annotation.points.map((annotation) => ({
              position: new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z),
            })),
            visible: annotation.visible,
            hiddenLabels: annotation.hiddenLabels,
            color: color,
            finished: true,
            currentTool: viewer.measuringTool.currentTool,
            identifier: annotation.identifier,
            selected: currentMeasurement?.selected || false,
          });
          viewer.scene.addMeasurement(measure);
        } else if (annotation.__typename === 'AreaAnnotation') {
          const currentMeasurement = currentMeasurementsByIdentifier[annotation.identifier];
          const calculatedVolumes = calculationsByIdentifier[annotation.identifier]
            ? calculationsByIdentifier[annotation.identifier]
                .filter((calculation) => !calculation.isOutDated)
                .map((calculation) => {
                  if (calculation.result?.__typename === 'VolumeCalculationResult') {
                    return calculation.result.volume;
                  }
                  return null;
                })
                .filter(isNotNullOrUndefined)
            : [];
          if (currentMeasurement) {
            currentMeasurement.visible = annotation.visible;
            currentMeasurement.setHiddenLabels(annotation.hiddenLabels);
            const pointsAreSame =
              annotation.points.length === currentMeasurement.points.length &&
              annotation.points.every((point, index) => {
                const currentPoint = currentMeasurement.points[index];
                return new THREE.Vector3(point.position.x, point.position.y, point.position.z).equals(
                  currentPoint.position,
                );
              });
            const volumesAreSame =
              currentMeasurement.volumes.length === calculatedVolumes.length &&
              currentMeasurement.volumes.every((volume, index) => volume === calculatedVolumes[index]);
            if (pointsAreSame && color.equals(currentMeasurement.color) && volumesAreSame) return;
            viewer.scene.removeMeasurement(currentMeasurement);
          }
          const measure = new Potree.AreaAnnotation({
            viewer: viewer,
            points: annotation.points.map((annotation) => ({
              position: new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z),
            })),
            visible: annotation.visible,
            hiddenLabels: annotation.hiddenLabels,
            color: color,
            finished: true,
            currentTool: viewer.measuringTool.currentTool,
            identifier: annotation.identifier,
            selected: currentMeasurement?.selected || false,
          });
          measure.addVolumeLabels(calculatedVolumes);
          viewer.scene.addMeasurement(measure);
        }
      });
      currentMeasurements.forEach((measurement) => {
        if (measurement instanceof Potree.PointoramaAnnotation && !annotationsByIdentifier[measurement.identifier]) {
          viewer.scene.removeMeasurement(measurement);
        }
      });
    },
    [project?.state.annotations, calculationsByIdentifier],
  );
  const cadLayersArray = useMemo(() => Object.values(cadLayers).filter(isNotNullOrUndefined), [cadLayers]);
  const syncCadObjects = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!cadLayers || !viewer) return;
      const currentMeasurements = viewer.scene.measurements.filter((measure) => measure.finished);
      const currentMeasurementsByIdentifier = keyBy('identifier', currentMeasurements);
      const cadObjectsByIdentifier = getCadObjectsByIdentifier(cadLayersArray);
      cadLayersArray.forEach((layer) => {
        if (layer.status === 'INIT') return;
        layer.cadObjectGroups?.forEach((cadObjectGroup) => {
          cadObjectGroup.cadObjects.forEach((cadObject) => {
            const color = cadObject.color
              ? new THREE.Color(cadObject.color.r / 255, cadObject.color.g / 255, cadObject.color.b / 255)
              : new THREE.Color(1, 0, 0);
            if (cadObject.type === 'POINT') {
              const visible = cadObject.visible;
              const currentMeasurement = currentMeasurementsByIdentifier[cadObject.identifier];
              if (currentMeasurement) {
                currentMeasurement.visible = visible;
                return;
              }
              const position = new THREE.Vector3(cadObject.position.x, cadObject.position.y, cadObject.position.z);
              const measure = new Potree.PointCadObject({
                viewer,
                point: { position },
                visible: visible,
                color: color,
                finished: true,
                currentTool: viewer.measuringTool.currentTool,
                identifier: cadObject.identifier,
                selected: false,
              });
              viewer.scene.addMeasurement(measure);
            } else if (cadObject.type === 'LINE') {
              const visible = cadObject.visible;
              const currentMeasurement = currentMeasurementsByIdentifier[cadObject.identifier];
              if (currentMeasurement) {
                currentMeasurement.visible = visible;
                return;
              }
              const measure = new Potree.DistanceCadObject({
                viewer,
                points: cadObject.points.map((cadObject) => ({
                  position: new THREE.Vector3(cadObject.x, cadObject.y, cadObject.z),
                })),
                visible: visible,
                color: color,
                finished: true,
                currentTool: viewer.measuringTool.currentTool,
                identifier: cadObject.identifier,
                selected: false,
              });
              viewer.scene.addMeasurement(measure);
            } else if (cadObject.type === 'POLYGON') {
              const visible = cadObject.visible;
              const currentMeasurement = currentMeasurementsByIdentifier[cadObject.identifier];
              if (currentMeasurement) {
                currentMeasurement.visible = visible;
                return;
              }
              const measure = new Potree.AreaCadObject({
                viewer,
                points: cadObject.points.map((cadObject) => ({
                  position: new THREE.Vector3(cadObject.x, cadObject.y, cadObject.z),
                })),
                visible: visible,
                color: color,
                finished: true,
                currentTool: viewer.measuringTool.currentTool,
                identifier: cadObject.identifier,
                selected: false,
              });
              viewer.scene.addMeasurement(measure);
            }
          });
        });
        const cadLayerState = cadLayers[layer.identifier];

        if (isLoadedCadLayer(cadLayerState) && cadLayerState.status !== 'LOADED') {
          setCadLayers((layers) => ({ ...layers, [layer.identifier]: { ...cadLayerState, status: 'LOADED' } }));
        }
      });
      currentMeasurements.forEach((measurement) => {
        if (measurement instanceof Potree.CadObject && !cadObjectsByIdentifier[measurement.identifier]) {
          viewer.scene.removeMeasurement(measurement);
        }
      });
    },
    [cadLayers, cadLayersArray],
  );
  const syncBoxes = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.annotations || !viewer) return;
      const currentBoxes = viewer.scene.volumes
        .filter((volume) => volume instanceof Potree.BoxVolume)
        .filter((volume) => volume.finished);
      const currentBoxesByIdentifier = keyBy('identifier', currentBoxes);
      const annotationsByIdentifier = keyBy('identifier', project.state.annotations);

      project.state.annotations.forEach((annotation) => {
        if (annotation.__typename !== 'BoxAnnotation') return;
        const currentBox = currentBoxesByIdentifier[annotation.identifier];
        const position = new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z);
        const scale = new THREE.Vector3(annotation.scale.x, annotation.scale.y, annotation.scale.z);
        const rotation = new THREE.Vector3(annotation.rotation.x, annotation.rotation.y, annotation.rotation.z);
        const color = annotation.annotationColor
          ? new THREE.Color(
              annotation.annotationColor.r / 255,
              annotation.annotationColor.g / 255,
              annotation.annotationColor.b / 255,
            )
          : new THREE.Color(1, 0, 0);
        const filter = getPotreeFilter(annotation.annotationFilter?.clipMethod);
        if (currentBox) {
          if (
            position.x === currentBox.position.x &&
            position.y === currentBox.position.y &&
            position.z === currentBox.position.z &&
            scale.x === currentBox.scale.x &&
            scale.y === currentBox.scale.y &&
            scale.z === currentBox.scale.z &&
            rotation.x === currentBox.rotation.x &&
            rotation.y === currentBox.rotation.y &&
            rotation.z === currentBox.rotation.z &&
            color.equals(currentBox.color) &&
            annotation.visible === currentBox.visible &&
            filter === currentBox.assignedClipTask
          ) {
            return;
          }
          viewer.scene.removeVolume(currentBox);
          viewer.transformationTool.setTransformable(undefined);
        }
        const box = new Potree.BoxVolume({
          identifier: annotation.identifier,
          clip: true,
          finished: true,
          position,
          scale,
          rotation,
          color,
          visible: annotation.visible,
          selected: currentBox?.selected || false,
        });
        box.setClipTask(filter);
        viewer.volumeTool.scene.add(box);
        viewer.scene.addVolume(box);
      });
      currentBoxes.forEach((box) => {
        if (!annotationsByIdentifier[box.identifier]) {
          viewer.scene.removeVolume(box);
          viewer.transformationTool.setTransformable(undefined);
        }
      });
    },
    [project?.state.annotations],
  );
  const syncPointClusters = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.annotations || !viewer) return;
      const currentPointClusters = viewer.scene.pointClusters.filter((pCluster) => pCluster.finished);
      const currentPointclustersByIdentifier = keyBy('identifier', currentPointClusters);
      const annotationsByIdentifier = keyBy('identifier', project.state.annotations);

      project.state.annotations.forEach((annotation) => {
        if (annotation.__typename !== 'PointCluster') return;
        const currentPointCluster = currentPointclustersByIdentifier[annotation.identifier];
        const segments = annotation.segments.map((segment) => {
          return { pointcloudId: segment.pointcloudId, segmentId: segment.segmentId };
        });
        const selected = currentPointCluster?.selected || false;
        const active = currentPointCluster?.active || false;
        const sameSegments =
          JSON.stringify(segments.slice().sort()) === JSON.stringify(currentPointCluster?.segments.slice().sort());
        const filter = getPotreeFilter(annotation.annotationFilter?.clipMethod);

        if (currentPointCluster) {
          if (
            sameSegments &&
            currentPointCluster.clipTask === filter &&
            currentPointCluster.visible === annotation.visible &&
            currentPointCluster.classification === annotation.annotationClass
          )
            return;
          currentPointCluster.segments = segments;
          currentPointCluster.clipTask = filter;
          currentPointCluster.classification = annotation.annotationClass || -1;
          currentPointCluster.visible = annotation.visible;
        } else {
          const pointCluster = new Potree.PointCluster({
            identifier: annotation.identifier,
            segments,
            selected,
            active,
            clipTask: filter,
            classification: annotation.annotationClass || -1,
            visible: annotation.visible,
          });
          viewer.scene.addPointCluster(pointCluster);
          pointCluster.finish();
        }
      });

      currentPointClusters.forEach((pCluster) => {
        if (!annotationsByIdentifier[pCluster.identifier]) {
          viewer.scene.removePointCluster(pCluster);
        }
      });
    },
    [project?.state.annotations],
  );

  const syncPlanes = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.annotations || !viewer) return;
      const currentPlanes = viewer.scene.planes.filter((plane) => plane.finished);
      const currentPlanesByIdentifier = keyBy('identifier', currentPlanes);
      const annotationsByIdentifier = keyBy('identifier', project.state.annotations);

      project.state.annotations.forEach((annotation) => {
        if (annotation.__typename !== 'Plane') return;
        const currentPlane = currentPlanesByIdentifier[annotation.identifier];
        const filter = getPotreeFilter(annotation.annotationFilter?.clipMethod);
        const tolerance = annotation.annotationFilter?.tolerance;
        if (currentPlane) {
          const position = currentPlane.position;
          const normal = currentPlane.getNormal();

          if (currentPlane) {
            if (
              position.x === annotation.position.x &&
              position.y === annotation.position.y &&
              position.z === annotation.position.z &&
              VectorsAreAlmostEqual(normal, annotation.normal) && // normal is calculated, results might not be exactly the same
              currentPlane.visible === annotation.visible &&
              currentPlane.assignedClipTask === filter &&
              currentPlane.tolerance === tolerance
            ) {
              return;
            }
            viewer.scene.removePlane(currentPlane);
          }
        }
        const plane = new Potree.PointoramaPlane({
          identifier: annotation.identifier,
          finished: true,
          position: new THREE.Vector3(annotation.position.x, annotation.position.y, annotation.position.z),
          normal: new THREE.Vector3(annotation.normal.x, annotation.normal.y, annotation.normal.z),
          visible: annotation.visible,
          selected: currentPlane?.selected,
          clipTask: filter,
          tolerance: tolerance,
        });

        viewer.planeTool.scene.add(plane);
        viewer.scene.addPlane(plane);
      });

      currentPlanes.forEach((plane) => {
        if (!annotationsByIdentifier[plane.identifier]) {
          viewer.scene.removePlane(plane);
        }
      });
    },
    [project?.state.annotations],
  );

  const syncClassifications = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.customClasses || !viewer) return;

      const currentCustomClasses = viewer.classificationsInfo.classes.filter((c) => c.custom);
      const currentCustomClassesByIdentifier = keyBy('identifier', currentCustomClasses);
      const customClassesByIdentifier = keyBy('id', project.customClasses);

      project.customClasses.forEach((customClass) => {
        const currentClass = currentCustomClassesByIdentifier[customClass.id];
        const color: [number, number, number, number] = [
          customClass.color.r / 255,
          customClass.color.g / 255,
          customClass.color.b / 255,
          customClass.color.a,
        ];
        if (currentClass) {
          if (currentClass.name === customClass.name && currentClass.color === color) return;
          viewer.classificationsInfo.removeCustomClass({ identifier: currentClass.identifier });
        }

        viewer.classificationsInfo.addCustomClass({
          identifier: customClass.id,
          name: customClass.name,
          code: customClass.code,
          color,
        });
      });

      currentCustomClasses.forEach((c) => {
        if (!customClassesByIdentifier[c.identifier]) {
          viewer.classificationsInfo.removeCustomClass({ identifier: c.identifier });
        }
      });
    },
    [project?.customClasses],
  );

  const wmsLayersContextValue = useMemo(() => {
    const orderedIdentifiers = orderBy('index', 'asc', project?.state.wmsLayerOrderedIdentifiers);
    const wmsLayers = orderedIdentifiers
      .map(({ identifier }) => project?.state.wmsLayers?.find((layer) => layer.identifier === identifier))
      .filter(isNotNullOrUndefined);
    return { wmsLayers };
  }, [project?.state.wmsLayerOrderedIdentifiers, project?.state.wmsLayers]);

  const syncWmsLayers = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.wmsLayers || !viewer) return;
      if (!project.pointClouds.every((pointcloud) => initializedPointclouds.includes(pointcloud.id))) return;
      wmsLayersContextValue.wmsLayers.forEach((layer) => {
        if (layer.visible) viewer.addWmsLayer(layer);
        else viewer.removeWmsLayer({ identifier: layer.identifier });
      });
    },
    [initializedPointclouds, project?.pointClouds, project?.state.wmsLayers, wmsLayersContextValue.wmsLayers],
  );

  const orthophotoLayersContextValue = useMemo(() => {
    const orderedIdentifiers = orderBy('index', 'asc', project?.state.orthophotoLayerOrderedIdentifiers);
    const orthophotoLayers = orderedIdentifiers
      .map(({ identifier }) => project?.state.orthophotoLayers?.find((layer) => layer.identifier === identifier))
      .filter(isNotNullOrUndefined);
    return { orthophotoLayers };
  }, [project?.state.orthophotoLayerOrderedIdentifiers, project?.state.orthophotoLayers]);

  const getGeoTiffData = useCallback(async ({ url }: EventInput['data']) => {
    const worker = new Worker();
    const result = await new Promise<EventOutput>((resolve) => {
      worker.onmessage = (event: MessageEvent) => {
        resolve(event.data);
      };
      worker.postMessage({ url });
    });
    worker.terminate();
    return result;
  }, []);

  function assertEpsgJson(epsg: object): asserts epsg is Record<number, { proj4: string }> {
    if (!epsg) throw new Error('epsg.json is missing');
    if (typeof epsg !== 'object') throw new Error('epsg.json is not an object');
  }

  const transformGeoTiffCoordinates = useCallback(
    (origin: number[], crs: number) => {
      assertEpsgJson(epsg);
      const customCrs = ProjectionSystemsByEpsgCode[crs];
      const sourceCrs = customCrs
        ? ProjectionSystems[customCrs as keyof typeof ProjectionSystems].parameters
        : epsg[crs]?.proj4;
      const targetCrs =
        project?.settings?.projectionSystem &&
        ProjectionSystems[project.settings.projectionSystem as keyof typeof ProjectionSystems].parameters;
      const transformedCoordinates = sourceCrs && targetCrs ? proj4(sourceCrs, targetCrs, origin) : origin;
      return transformedCoordinates;
    },
    [project?.settings?.projectionSystem],
  );

  const syncOrthophotoLayers = useCallback(
    ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (!project?.state.orthophotoLayers || !viewer) return;
      if (!project.pointClouds.every((pointcloud) => initializedPointclouds.includes(pointcloud.id))) return;

      const sceneOrthophotos = viewer.scene.orthophotos;
      const sceneOrthophotosByIdentifier = keyBy('identifier', sceneOrthophotos);
      const databaseOrthophotosByIdentifier = keyBy('identifier', project.state.orthophotoLayers);

      orthophotoLayersContextValue.orthophotoLayers.forEach((layer) => {
        const currentOrthophoto = sceneOrthophotosByIdentifier[layer.identifier];
        if (currentOrthophoto) {
          if (currentOrthophoto.visible === layer.visible) return;
          else currentOrthophoto.visible = layer.visible;
        } else if (layer.url) {
          const orthophoto = viewer.scene.addOrthophotoLayer(layer.identifier, layer.elevation || 0);
          getGeoTiffData({ url: layer.url }).then((geotiff) => {
            orthophoto.initialise({
              visible: layer.visible,
              ...geotiff,
              origin: transformGeoTiffCoordinates(geotiff.origin, geotiff.crs),
              elevation: layer.elevation || 0,
            });
          });
        }
      });

      sceneOrthophotos.forEach((photo) => {
        if (!databaseOrthophotosByIdentifier[photo.identifier]) {
          viewer.scene.removeOrthophotoLayer({ identifier: photo.identifier });
        }
      });
    },
    [
      initializedPointclouds,
      project?.pointClouds,
      project?.state.orthophotoLayers,
      orthophotoLayersContextValue.orthophotoLayers,
      getGeoTiffData,
      transformGeoTiffCoordinates,
    ],
  );

  useEffect(() => {
    if (project?.state) {
      commander.setState({
        ...project.state,
        cadLayers: project.state.cadLayers.map((cadLayer) => ({
          ...cadLayer,
          type: 'CAD',
          visibleState: cadLayer.visibleState
            .map((state) => {
              if (state.__typename === 'CadLayerVisibleStateDelete') return { ...state, type: 'DELETE' as const };
              else if (state.__typename === 'CadLayerVisibleStateHidden') return { ...state, type: 'HIDDEN' as const };
              return null;
            })
            .filter(isNotNullOrUndefined),
        })),
        pointClouds: project.pointClouds.map((pc) => ({ name: pc.displayName, identifier: pc.id })),
        wmsLayers: project.state.wmsLayers || [],
      });
      syncAnnotations({ viewer: rendererContext.viewer });
      syncCadObjects({ viewer: rendererContext.viewer });
      syncWmsLayers({ viewer: rendererContext.viewer });
      syncOrthophotoLayers({ viewer: rendererContext.viewer });
      syncBoxes({ viewer: rendererContext.viewer });
      syncPointClusters({ viewer: rendererContext.viewer });
      syncClassifications({ viewer: rendererContext.viewer });
      syncPlanes({ viewer: rendererContext.viewer });
    }
  }, [
    commander,
    project?.state,
    project?.pointClouds,
    syncAnnotations,
    syncCadObjects,
    syncPointClusters,
    rendererContext.viewer,
    syncWmsLayers,
    syncOrthophotoLayers,
    syncBoxes,
    cadLayers,
    syncClassifications,
    syncPlanes,
  ]);

  useEffect(() => {
    const viewer = rendererContext.viewer;
    if (!viewer) return;
    project?.pointClouds.forEach((pointCloud) => {
      const renderedPointCloud = viewer.scene.pointclouds.find((pc) => pc.identifier === pointCloud.id);
      if (renderedPointCloud) {
        renderedPointCloud.visible = pointCloud.visible;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.pointClouds]);

  const setPreview = useCallback(
    async ({ viewer }: { viewer: RendererContextType['viewer'] }) => {
      if (project?.previewUrl) return;
      const blob = viewer?.getRenderPreview();
      if (!blob) return;
      const fileName = `defaultrenderpreview.png`;
      const file = new File([blob], fileName, { type: 'image/png' });

      await setAndUploadPreview(file, projectId);
    },
    [setAndUploadPreview, projectId, project],
  );

  useLayoutEffect(() => {
    if (!hasProject || !sourcesLoaded || pointcloudLoaded) return;
    if (!project.pointClouds) return;
    console.log('start loading pointclouds');
    setPointcloudLoaded(true);

    const loadPointCloudHeaderFiles = async ({ viewer }: { viewer: any }) => {
      const SASUrl = await projectRequestReadSASToken({ variables: { projectId } });
      const SAStoken = SASUrl.data?.projectRequestReadSASToken || '';
      localStorage.setItem('SASToken_' + projectId, SAStoken);
      Potree.projectId = projectId;
      project.pointClouds.forEach((pointCloud) => {
        Potree.loadPointCloud(
          `/${projectId}/${pointCloud.cloudName}.ppch?${SAStoken}`,
          `${pointCloud.cloudName}`,
          (e) => {
            console.log(`loadPointCloudHeaderFiles:: pointcloud loaded: ${pointCloud.cloudName}`);
            if (project.settings?.projectionSystem) {
              const projection =
                ProjectionSystems[project.settings.projectionSystem as keyof typeof ProjectionSystems].parameters;
              e.pointcloud.projection = projection;
            }
            const defaultState = defaultPointCloudState[pointCloud.id] || {};
            if (defaultState.resolution !== undefined && defaultState.resolution !== null)
              changePointCloudResolution({ pointCloud: e.pointcloud, value: defaultState.resolution });
            if (defaultState.appearance)
              changePointCloudAppearance({ pointCloud: e.pointcloud, value: defaultState.appearance });
            if (defaultState.classifications)
              defaultState.classifications.forEach((classification) =>
                viewer.setClassificationVisibility({
                  key: classification.code,
                  value: classification.visible,
                  pointCloudId: pointCloud.id,
                }),
              );
            if (defaultState.gradient)
              changePointCloudGradient({ pointCloud: e.pointcloud, gradient: defaultState.gradient });
            if (defaultState.heightMax !== undefined) e.pointcloud.material.heightMax = defaultState.heightMax;
            if (defaultState.heightMin !== undefined) e.pointcloud.material.heightMin = defaultState.heightMin;
            if (defaultState.intensityMax !== undefined) e.pointcloud.material.intensityMax = defaultState.intensityMax;
            if (defaultState.intensityMin !== undefined) e.pointcloud.material.intensityMin = defaultState.intensityMin;
            viewer.scene.addPointCloud(e.pointcloud);
            const material = e.pointcloud.material;
            material.size = 1;
            material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
            viewer.fitToScreen();
            viewer.setTopView();
            if (project.settings?.projectionSystem) {
              if (project.mapVisible) {
                viewer.viewerMode.enable2DModeFromMap();
                viewer.showMap();
              }
            }
            setInitializedPointclouds((pointclouds) => pointclouds.concat(pointCloud.id));
          },
          false,
          pointCloud.id,
          pointCloud.visible,
        );
      });
    };

    const initializeViewer = () => {
      // @ts-ignore
      const viewer = new Potree.Viewer(document.getElementById('potree_render_area'), { useDefaultRenderLoop: false });
      console.log('viewer initialized');
      setRendererContext({ viewer });
      viewer.setEDLEnabled(true);
      viewer.setFOV(60);
      viewer.setPointBudget(3 * 1000 * 1000);
      viewer.loadSettingsFromURL();
      viewer.loadGUI(() => {
        viewer.setLanguage('en');
        viewer.toggleSidebar();
      });
      if (localStorage.theme === 'dark') {
        viewer.setBackground('dark');
      } else {
        viewer.setBackground('light');
      }
      if (measurementUnit === MeasurementUnits.Feet) {
        viewer.setLengthUnitAndDisplayUnit('m', 'ft');
      } else {
        viewer.setLengthUnitAndDisplayUnit('m', 'm');
      }
      console.log('start loading header files');
      loadPointCloudHeaderFiles({ viewer });
      setTimeout(() => {
        setPreview({ viewer });
      }, 10000);
    };

    initializeViewer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectId,
    projectRequestReadSASToken,
    sourcesLoaded,
    hasProject,
    project?.settings?.projectionSystem,
    project?.mapVisible,
    setPointcloudLoaded,
    setInitializedPointclouds,
    pointcloudLoaded,
    project?.pointClouds,
  ]);

  const annotationsByIdentifier = useMemo(
    () => keyBy('identifier', project?.state?.annotations || []),
    [project?.state?.annotations],
  );
  const annotations = useMemo(() => {
    const annotationsByGroupId = groupBy('groupIdentifier', project?.state.annotations);
    const groupsById = keyBy('identifier', project?.state.groups);
    const orderedIdentifiers = orderBy('index', 'asc', project?.state.orderedIdentifiers);
    const annotations: AnnotationContextType['annotations'] = orderedIdentifiers.reduce(
      (result, orderInfo) => {
        const annotation = annotationsByIdentifier[orderInfo.identifier];
        if ((annotation as Annotation)?.groupIdentifier) return result;
        const group = groupsById[orderInfo.identifier];
        if (group) {
          const annotations = annotationsByGroupId[group.identifier] || [];
          const annotationsById = keyBy('identifier', annotations);
          const orderedIdentifiers = project?.state.orderedIdentifiers.filter(
            ({ identifier }) => !!annotationsById[identifier],
          );
          return [
            ...result,
            {
              ...group,
              annotations: orderBy('index', 'asc', orderedIdentifiers).map(
                (orderInfo) => annotationsById[orderInfo.identifier],
              ),
            },
          ];
        }
        return [...result, annotation];
      },
      [] as AnnotationContextType['annotations'],
    );
    return annotations;
  }, [annotationsByIdentifier, project?.state.annotations, project?.state.groups, project?.state.orderedIdentifiers]);

  const annotationContextValue = useMemo(() => {
    return { annotations, annotationsByIdentifier, calculationsByIdentifier };
  }, [annotations, annotationsByIdentifier, calculationsByIdentifier]);

  const cadLayersContextValue = useMemo(() => {
    return { cadLayers: cadLayersArray.slice() };
  }, [cadLayersArray]);

  const readOnly =
    !currentUser.isSuperAdmin &&
    currentUser.rolesByOrganisation.find((value) => value.organisationId === organisationId)?.role === UserRole.Guest;

  return (
    <RendererReadOnlyContext.Provider value={readOnly}>
      <RendererContext.Provider value={rendererContext}>
        <ViewerContextProvider project={project}>
          <CommandManagerContext.Provider value={commandManagerContext}>
            <AnnotationContext.Provider value={annotationContextValue}>
              <CadObjectContext.Provider value={cadLayersContextValue}>
                <WMSLayersContext.Provider value={wmsLayersContextValue}>
                  <OrthophotoLayersContext.Provider value={orthophotoLayersContextValue}>
                    <ProjectContent project={project} />
                  </OrthophotoLayersContext.Provider>
                </WMSLayersContext.Provider>
              </CadObjectContext.Provider>
            </AnnotationContext.Provider>
          </CommandManagerContext.Provider>
        </ViewerContextProvider>
      </RendererContext.Provider>
    </RendererReadOnlyContext.Provider>
  );
};

export const Project = memo(Project_);
