import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { RendererContext } from './RendererContext';
import { RendererToolType, useRendererTools } from '../hooks/potree/useRenderer';
import { ProjectByIdQuery } from '../types/graphqlTypes';

export type ViewerContextType = {
  in2DMode: boolean;
  planeId: string;
  toggle2DMode: (planeId: string) => void;
  enable2DMode: (planeId: string) => void;
  disable2DMode: () => void;
};
export const ViewerContext = createContext<ViewerContextType>({
  in2DMode: false,
  planeId: '',
  toggle2DMode: () => {
    return;
  },
  enable2DMode: () => {
    return;
  },
  disable2DMode: () => {
    return;
  },
});

export const ViewerContextProvider = ({
  project,
  children,
}: {
  project?: ProjectByIdQuery['projectById'];
  children: ReactNode;
}) => {
  const [in2DMode, set2DMode] = useState<boolean>(false);
  const [planeId, setPlaneId] = useState<string>('');
  const [initialised, setInitialised] = useState<boolean>(false);
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const [_, { changeTool }] = useRendererTools();

  useEffect(() => {
    if (initialised || !project) return;

    if (project.mapVisible) {
      set2DMode(true);
      setPlaneId('map');
    }
    setInitialised(true);
  }, [project, initialised]);

  const toggle2DMode = useCallback(
    (newPlaneId: string) => {
      if (!viewer) return;

      if (in2DMode) {
        if (planeId === newPlaneId) {
          set2DMode(false);
          setPlaneId('');
          viewer.viewerMode.disable2DMode();
          changeTool({ type: RendererToolType.SELECT });
        } else {
          setPlaneId(newPlaneId);
          viewer.viewerMode.enable2DModeFromPlane(newPlaneId);
        }
      } else {
        set2DMode(true);
        setPlaneId(newPlaneId);
        viewer.viewerMode.enable2DModeFromPlane(newPlaneId);
        changeTool({ type: RendererToolType.SELECT });
      }
    },
    [changeTool, in2DMode, planeId, viewer],
  );

  const enable2DMode = useCallback(
    (newPlaneId: string) => {
      if (!in2DMode) {
        changeTool({ type: RendererToolType.SELECT });
        set2DMode(true);
      }
      if (planeId !== newPlaneId) {
        setPlaneId(newPlaneId);
        viewer?.viewerMode.enable2DModeFromPlane(newPlaneId);
      }
    },
    [changeTool, in2DMode, planeId, viewer],
  );

  const disable2DMode = useCallback(() => {
    if (in2DMode) {
      changeTool({ type: RendererToolType.SELECT });
      set2DMode(false);
      setPlaneId('');
      viewer?.viewerMode.disable2DMode();
    }
  }, [changeTool, in2DMode, viewer]);

  return (
    <ViewerContext.Provider value={{ in2DMode, planeId, toggle2DMode, enable2DMode, disable2DMode }}>
      {children}
    </ViewerContext.Provider>
  );
};
