import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import ArrowExpandIcon from '../../../assets/icons/arrow-expand.svg?react';
import FitToScreenIcon from '../../../assets/icons/fit-to-screen.svg?react';
import { useScreenTools } from '../../../hooks/potree/useScreenTools';
import { Project } from '../../../types/graphqlTypes';
import classNames from 'classnames';
import { memo, useCallback, useContext } from 'react';
import { IconBox } from '../../../components/IconBox';
import { Tooltip } from '../../../components/Tooltip';
import { T } from '../../../translation/src';
import { ViewerContext } from '../../../contexts/ViewerContext';
import { useToggleHide } from '../../../hooks/modules/project/useToggleHide';

interface ScreenToolsProps {
  project?: Pick<Project, 'id' | 'mapVisible' | 'settings'>;
}

const MapTool_: React.FC2<ScreenToolsProps> = ({ className, project, ...props }) => {
  const { in2DMode, enable2DMode, disable2DMode } = useContext(ViewerContext);
  const { toggleHide } = useToggleHide();

  const on2D = useCallback(() => {
    enable2DMode('map');
  }, [enable2DMode]);

  const on3D = useCallback(() => {
    if (in2DMode) {
      toggleHide({ identifiers: ['map'], visible: false });
      disable2DMode();
    }
  }, [disable2DMode, in2DMode, toggleHide]);

  return (
    <div
      className={classNames(
        'flex bg-white dark:bg-black dark:border-gray-700 dark:text-white rounded-lg divide-x-2 divide-gray-700 z-10 w-auto overflow-hidden',
        className,
      )}
      {...props}
    >
      <IconBox text={'2D'} highlight={in2DMode} grayedOut={!in2DMode} onClick={on2D} />

      <IconBox text={'3D'} highlight={!in2DMode} grayedOut={in2DMode} onClick={on3D} />
    </div>
  );
};

const ScreenTools_: React.FC2<ScreenToolsProps> = ({ className, project, ...props }) => {
  const [{ fitToScreen, onFullScreen }, { onZoomInStart, onZoomOutStart, onZoomEnd }] = useScreenTools({ project });
  return (
    <div
      className={classNames(
        'flex bg-white dark:bg-black dark:border-gray-700 dark:text-white rounded-lg divide-x-2 divide-gray-700 z-10 w-auto',
        className,
      )}
      {...props}
    >
      {/* <IconBox icon={CubeIcon} */}
      {document.fullscreenEnabled && (
        <Tooltip message={<T _str="expand" swc />} delay={0}>
          <IconBox icon={ArrowExpandIcon} onClick={onFullScreen} />
        </Tooltip>
      )}
      <Tooltip message={<T _str="fit to screen" swc />} delay={0}>
        <IconBox icon={FitToScreenIcon} onClick={fitToScreen} />
      </Tooltip>
      <Tooltip message={<T _str="zoom out" swc />} delay={0}>
        <IconBox icon={MinusIcon} onMouseDown={onZoomOutStart} onMouseUp={onZoomEnd} />
      </Tooltip>
      <Tooltip message={<T _str="zoom in" swc />} delay={0}>
        <IconBox icon={PlusIcon} onMouseDown={onZoomInStart} onMouseUp={onZoomEnd} />
      </Tooltip>
    </div>
  );
};

export const ScreenTools = memo(ScreenTools_);
export const MapTool = memo(MapTool_);
